import { render, staticRenderFns } from "./Watermark.vue?vue&type=template&id=2ed411aa&scoped=true&"
import script from "./Watermark.vue?vue&type=script&lang=js&"
export * from "./Watermark.vue?vue&type=script&lang=js&"
import style0 from "./Watermark.vue?vue&type=style&index=0&id=2ed411aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed411aa",
  null
  
)

export default component.exports