<template>
    <div class="markdown" v-html="htmlText"></div>
</template>
<script>
export default {
    name: 'markdown',
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            htmlText: '',
        }
    },
    created() {
        // <right></right>
        // <center></center>
        // <underline></underline>
        // <bold></bold>
        // <italic></italic>
        // <indent></indent>
        // <u-blank>
        const elMap = [
            { el: '<right>', output: '<div class="right">' },
            { el: '</right>', output: '</div>' },
            { el: '<center>', output: '<div class="center">' },
            { el: '</center>', output: '</div>' },
            { el: '<underline>', output: '<div class="underline">' },
            { el: '</underline>', output: '</div>' },
            { el: '<bold>', output: '<div class="bold">' },
            { el: '</bold>', output: '</div>' },
            { el: '<italic>', output: '<div class="italic">' },
            { el: '</italic>', output: '</div>' },
            { el: '<indent>', output: '<div class="indent">' },
            { el: '</indent>', output: '</div>' },
            { el: '<u-blank>', output: '<div class="u-blank"></div>' },
        ]
        let htmlText = this.text
        elMap.forEach((item) => {
            htmlText = htmlText.replace(new RegExp(item.el, 'g'), item.output)
        })
        this.htmlText = htmlText
    },
}
</script>
<style lang="scss" scoped>
.markdown {
    white-space: pre-wrap;
}
::v-deep .right {
    text-align: right;
}
::v-deep .center {
    text-align: center;
}
::v-deep .underline {
    display: inline;
    text-decoration: underline;
}
::v-deep .bold {
    display: inline;
    font-weight: 700;
}
::v-deep .italic {
    display: inline;
    font-style: italic;
}
::v-deep .u-blank {
    display: inline-block;
    text-decoration: underline;
    &::after {
        content: '     ';
        display: inline-block;
        text-decoration: underline;
    }
}
::v-deep .indent {
    text-indent: 2em;
}
</style>
