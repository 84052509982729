<template>
    <div class="audio" v-if="question.parentType === 'LISTENING'">
        <div class="title">{{ question.parentTitle }}</div>
        <audio controls="controls" controlsList="nodownload" :src="question.parentContent">
            您的浏览器不支持 audio 标签。
        </audio>
        <ImagePreview :picUrl="question.parentPicUrl" v-if="question.parentPicUrl" />
    </div>
</template>
<script>
import ImagePreview from './ImagePreview.vue'

export default {
    props: ['question'],
    components: { ImagePreview },
}
</script>
<style lang="scss" scoped>
.title {
    margin: 12px 0;
    text-indent: 2em;
}
</style>
