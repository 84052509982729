<template>
    <div
        class="watermark"
        id="watermark"
        :style="`background-image: url(${userId}),url(${userId}),url(${userId});`"
    ></div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            userId: '',
        }
    },
    computed: { ...mapGetters(['userInfo']) },
    watch: {
        userInfo: function () {
            this.userId = this.createWatermark()
        },
    },
    created() {
        this.userId = this.createWatermark()
    },
    methods: {
        createWatermark() {
            const _text = this.userInfo ? this.userInfo.id : ''
            const _canvas = document.createElement('canvas')
            _canvas.setAttribute('width', 300)
            _canvas.setAttribute('height', 200)
            const _ctx = _canvas.getContext('2d')
            _canvas.style.display = 'none'

            _ctx.rotate((-10 * Math.PI) / 180)
            _ctx.font = '20px Arial'
            _ctx.fillStyle = '#000'
            _ctx.textAlign = 'left'
            _ctx.textBaseline = 'middle'
            _ctx.fillText(_text, 150, 100)
            _ctx.fillStyle = '#fff'
            _ctx.textAlign = 'right'
            _ctx.textBaseline = 'middle'
            _ctx.fillText(_text + ' ', 150, 100)
            return _canvas.toDataURL('image/png')
        },
    },
}
</script>
<style lang="scss" scoped>
.watermark {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 999;
    background-repeat: repeat;
    opacity: 0.02;
    background-position: 150px 100px, 0 0;
}
</style>
